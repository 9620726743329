import React from 'react';

import { PageTitle, TitleForm, TutorialHeader } from '@goodfynd/react-web.lib.content';

import config from '../../config';

import type { ComponentType } from './types';

import type {
  PageTitleProps,
  TitleFormProps,
  TutorialHeaderProps,
} from '@goodfynd/react-web.lib.content';
export default function renderCmsTitleComponent(
  index: number,
  componentType: ComponentType,
  fields: Record<string, unknown>
): unknown {
  switch (componentType) {
    case 'page_title':
      return (
        <PageTitle
          {...(fields as unknown as PageTitleProps)}
          key={componentType + index}
        />
      );

    case 'panel_form_with_image':
      return (
        fields.hubspot_form_id && (
          <TitleForm
            {...(fields as Omit<TitleFormProps, 'form'>)}
            hubspot_form_id={fields.hubspot_form_id as string}
            hubspot_portal_id={config.env.HUBSPOT_PORTAL_ID}
            key={componentType + index}
          />
        )
      );

    case 'tutorial_header':
      return (
        <TutorialHeader
          {...(fields as unknown as TutorialHeaderProps)}
          key={componentType + index}
        />
      );

    default:
      return null;
  }
}
