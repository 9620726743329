import { convertDistance } from 'geolib';
import React, { useCallback, useEffect } from 'react';
import usePlacesAutocomplete, { getGeocode } from 'use-places-autocomplete';

import { useGoogleApi } from '@goodfynd/react-web.context.google-api-context';
import { StyledFlex } from '@goodfynd/react-web.styles';
import { Text } from '@goodfynd/react-web.typography.text';
import { Loading } from '@goodfynd/react-web.ui.loading';
import Autocomplete from '@mui/material/Autocomplete';
import InputAdornment from '@mui/material/InputAdornment';

import { Icon } from '../../components/Icon';
import Theme from '../../components/Theme';
import config, { unicode } from '../../config';
import strings from '../../config/strings';
import { useLocation, useLocationDispatch } from '../../context/location-context';
import * as storageUtil from '../../utils/storage-util';
import { StyledTextField } from './styles';

import type { InputGeoSearchProps, InputPage } from './types';
export default function useGeoSearch({
  store = true,
}: InputGeoSearchProps = {}) {
  const { isLoaded: googleApiIsLoaded } = useGoogleApi();

  const location = useLocation();
  const { setAddress, setPosition } = useLocationDispatch();
  const { position } = location;

  const {
    init,
    ready,
    value,
    suggestions: { data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    initOnMount: false,
    requestOptions: global.window?.google?.maps &&
      position && {
        bounds: new google.maps.LatLngBounds({
          lat: position.latitude,
          lng: position.longitude,
        }),
        origin: new google.maps.LatLng({
          lat: position.latitude,
          lng: position.longitude,
        }),
      },
    debounce: 300,
  });

  useEffect(() => {
    if (googleApiIsLoaded) {
      init();
    }
  }, [googleApiIsLoaded, init]);

  useEffect(() => {
    if (ready && location.address?.label) {
      setValue(location.address.label);
    }
  }, [location.address?.label, ready, setValue]);

  if (!googleApiIsLoaded || location.busy || !ready) {
    <Loading type="circle" />;
  }

  const getSuggestions = useCallback<() => SelectOption[]>(
    () =>
      data.map(
        ({
          distance_meters,
          place_id,
          structured_formatting: { main_text, secondary_text },
        }) => ({
          id: place_id,
          label: main_text,
          subLabel: [
            secondary_text,
            distance_meters && distance_meters > 0
              ? `${convertDistance(distance_meters, 'mi').toFixed(1)} mi.`
              : undefined,
          ]
            .filter(Boolean)
            .join(unicode.centerDotPadded),
          value: place_id,
        })
      ),
    [data]
  );

  const handleGeoSearch = useCallback(
    (e: HtmlInputChangeEvent) => setValue(e.target.value),
    [setValue]
  );

  const handleLocationSelect = useCallback(
    ({ id, label }: SelectOption) => {
      // Set geo search input without making another API call
      setValue(label, false);
      clearSuggestions();

      // Store latitude and longitude
      getGeocode({ address: id ? '' : label, placeId: id })
        .then((results) => {
          const { formatted_address, geometry, place_id } = results[0];
          const placeAddress = {
            id: place_id,
            label: formatted_address,
          };
          const { location: geoLocation } = geometry;
          return {
            latitude: geoLocation.lat(),
            longitude: geoLocation.lng(),
            placeAddress,
          };
        })
        .then(({ latitude, longitude, placeAddress }) => {
          console.info('📍 Selected Location: ', { latitude, longitude });
          setAddress(placeAddress);
          setPosition({ latitude, longitude });

          if (store) {
            storageUtil.local.set(config.storageKeys.userLocation, {
              latitude,
              longitude,
              placeAddress,
              coordinates: `${latitude}, ${longitude}`,
            });
          }
        })
        .catch((error) => {
          console.error('😱 Error: ', error);
        });
    },
    [clearSuggestions, setAddress, setPosition, setValue, store]
  );

  const renderInput = useCallback(
    (page: InputPage = 'home', position?: TextFieldPosition) => {
      const options = getSuggestions();
      return (
        <Autocomplete<SelectOption | string, undefined, boolean>
          id="geo-search"
          disableClearable
          getOptionLabel={(option: string | SelectOption) =>
            typeof option === 'string'
              ? option
              : [option.label, option.subLabel]
                  .filter(Boolean)
                  .join(unicode.centerDotPadded)
          }
          inputMode="search"
          inputValue={value}
          loading={!ready}
          onChange={(
            _e: React.SyntheticEvent,
            option: SelectOption | string | null
          ) => option && handleLocationSelect(option as SelectOption)}
          options={options}
          renderInput={(params) => (
            <StyledTextField
              {...params}
              fullWidth={false}
              onChange={handleGeoSearch}
              page={page}
              placeholder={strings.labels.homeGeoPlaceholder}
              position={
                position || (page === 'home' ? 'input-bottom' : 'input-right')
              }
              InputProps={{
                ...params.InputProps,
                startAdornment: (
                  <InputAdornment position="start">
                    <Icon name="location_pin" />
                  </InputAdornment>
                ),
              }}
            />
          )}
          renderOption={(props, option) => (
            <Theme key={props.id}>
              <li {...props}>
                <StyledFlex
                  css={{
                    alignItems: 'flex-start',
                    flexDirection: 'column',
                  }}
                  gap={2}
                >
                  {typeof option === 'string' ? (
                    <Text>{option}</Text>
                  ) : (
                    <>
                      <Text>{option.label}</Text>
                      {option.subLabel && (
                        <Text type="caption">{option.subLabel}</Text>
                      )}
                    </>
                  )}
                </StyledFlex>
              </li>
            </Theme>
          )}
        />
      );
    },
    [getSuggestions, handleGeoSearch, handleLocationSelect, ready, value]
  );

  return { location, ready, renderInput, value };
}
