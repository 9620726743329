import { observer } from 'mobx-react-lite';
import { useRouter } from 'next/router';
import React, { useState } from 'react';

import { InputAdornment } from '@mui/material';

import strings from '../../config/strings';
import { useDebounce } from '../../hooks';
import useGeoSearch from '../../hooks/useGeoSearch';
import { Icon } from '../Icon';
import {
  StyledSearchButton,
  StyledSearchInputsContainer,
  StyledTextField,
} from './styles';

export default observer(function PanelSearchInputs(): JSX.Element {
  const router = useRouter();
  const [searchTerm, setSearchTerm] = useState('');

  useDebounce(() => console.debug(`You searched for: ${searchTerm}!`), 500, [
    searchTerm,
  ]);

  const geoSearch = useGeoSearch();

  const handleSearch = (e: ClickButtonEvent) => {
    e.preventDefault();

    router.push(
      `/search?${new URLSearchParams({
        q: searchTerm,
      }).toString()}`
    );
  };

  const handleSearchTermChange = (e: HtmlInputChangeEvent) =>
    setSearchTerm(e.target.value);

  return (
    <form>
      <StyledSearchInputsContainer>
        <StyledTextField
          fullWidth
          id="search"
          onChange={handleSearchTermChange}
          placeholder={strings.labels.homeSearchPlaceholder}
          position="input-top"
          value={searchTerm}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Icon name="search" />
              </InputAdornment>
            ),
          }}
        />

        {geoSearch.renderInput()}
      </StyledSearchInputsContainer>

      <StyledSearchButton
        onClick={handleSearch}
        track={{
          id: 'search',
          page_destination: `/search?${new URLSearchParams({
            q: searchTerm,
          }).toString()}`,
        }}
        type="submit"
      >
        {strings.labels.letsEat}
      </StyledSearchButton>
    </form>
  );
});
