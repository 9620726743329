import { StyledSearchTextField } from '../../context/search-context/SearchInputs/styles';
import { styled } from '../../theme/stitches.config';
import Button from '../Button';

export const StyledSearchButton = styled(Button, {
  '&.MuiButton-root': {
    marginTop: 16,
    width: '100%',
    '@lg': {
      width: 'auto',
    },
  },
});

export const StyledSearchInputsContainer = styled('aside', {
  marginTop: 24,
  overflow: 'hidden',
});

export const StyledTextField = styled(StyledSearchTextField, {
  '&.MuiTextField-root input': {
    paddingLeft: 6,
  },
});
