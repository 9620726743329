import Head from 'next/head';
import React, { useCallback } from 'react';

import { PromoCta, TitleForm } from '@goodfynd/react-web.lib.content';

import PanelSearchInputs from '../components/PanelSearch/PanelSearchInputs';
import { useCmsPage } from '../hooks';
import { scrollToTop, setPageTitle } from '../utils/base';
import {
  renderCmsBodyCompnent,
  renderCmsTitleComponent,
} from '../utils/cms-util';

import type {
  PromoCtaProps,
  TitleFormProps,
} from '@goodfynd/react-web.lib.content';
export default function Home(): JSX.Element {
  const { body, seo, title } = useCmsPage('consumer-home-page');

  const renderBody = useCallback(
    () =>
      body?.map(({ fields, type }, index) => {
        switch (type) {
          case 'two_column_image_left':
            return (
              <PromoCta
                {...(fields as PromoCtaProps)}
                buttonOnClick={function () {
                  scrollToTop();
                }}
                key={type + index}
              />
            );

          case 'two_column_image_right':
            return (
              <PromoCta
                image_position="right"
                {...(fields as PromoCtaProps)}
                buttonOnClick={function () {
                  scrollToTop();
                }}
                key={type + index}
              />
            );

          default:
            return renderCmsBodyCompnent(index, type, fields);
        }
      }),
    [body]
  );

  const renderTitle = useCallback(
    () =>
      title?.map(({ fields, type }, index) => {
        switch (type) {
          case 'panel_form_with_image':
            return (
              <TitleForm
                {...(fields as Omit<
                  TitleFormProps,
                  'hubspot_form_id' | 'hubspot_portal_id'
                >)}
                form={<PanelSearchInputs />}
                key={type + index}
              />
            );

          default:
            return renderCmsTitleComponent(index, type, fields);
        }
      }),

    [title]
  );

  return (
    <>
      {seo && (
        <Head>
          <title>{setPageTitle(seo?.title)}</title>
          <meta name="description" content={seo?.description} />
        </Head>
      )}

      {/* <div style={{ paddingTop: header ? 64 : 0 }}> */}
      {/* {header && <CmsHeader {...(header as unknown as CmsHeaderProps)} />} */}

      {renderTitle()}

      <div
        style={{
          marginTop: 40,
        }}
      >
        {renderBody()}
      </div>

      {/* {footer && (
        <Footer
          drift_interaction_id={config.env.DRIFT_INTERACTION_ID}
          {...(footer as unknown as FooterProps)}
        />
      )} */}
      {/* </div> */}
    </>
  );
}
